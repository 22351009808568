import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import ReactGA from "react-ga";

import MainHeader from "../UI/MainHeader";
import Footer from "../UI/Footer";
import ScrollToTop from "./ScrollToTop";

const Homepage = React.lazy(() => import("../../pages/Homepage"));
const About = React.lazy(() => import("../../pages/About"));
const MyWork = React.lazy(() => import("../../pages/MyWork"));
const Thanxs = React.lazy(() => import("../../pages/caseStudies/Thanxs"));
const Cookbook = React.lazy(() => import("../../pages/caseStudies/Cookbook"));
const Branding = React.lazy(() => import("../../pages/caseStudies/Branding"));
const DiscoverSeville = React.lazy(() =>
  import("../../pages/caseStudies/DiscoverSeville")
);

ReactGA.pageview(window.location.pathname);

function App() {
  return (
    <ScrollToTop>
      <div className="uppersection">
        <div className="container">
          <MainHeader />
        </div>
      </div>
      <main>
        <Suspense fallback={<div className="loading">Loading...</div>}>
          <Switch>
            <Route path="/" exact>
              <Homepage />
            </Route>
            <Route path="/mywork" exact>
              <MyWork />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/mywork/thanx-recognition-app" exact>
              <Thanxs />
            </Route>
            <Route path="/mywork/green-family-cookbook" exact>
              <Cookbook />
            </Route>
            <Route path="/mywork/branding" exact>
              <Branding />
            </Route>
            <Route path="/mywork/discover-seville" exact>
              <DiscoverSeville />
            </Route>
          </Switch>
        </Suspense>
      </main>
      <Footer></Footer>
    </ScrollToTop>
  );
}

export default App;
